<template>
  <KeepAlive>
    <router-view />
  </KeepAlive>
</template>

<script>
export default {
  name: "CustomersBase",
};
</script>

<style></style>
